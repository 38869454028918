<template>
  <div class="modules" v-if="inputVal">
    <!-- level-1 Modules -->
    <b-card
      no-body
      class="mb-2"
      v-for="(item, index) in inputVal.length"
      v-bind:key="index"
    >
      <module v-model="inputVal[index]" ref="module"></module>
    </b-card>
    <!-- level-1 Modules -->
  </div>
</template>

<script>
import module from "./Module.vue";
import UserPermissionsMixin from "@/mixins/UserPermissionsMixin";

export default {
  mixins: [UserPermissionsMixin],
  name: "Modules",
  props: {
    value: null,
    msg: String,
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    module,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
