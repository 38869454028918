<template>
  <div class="resources">
    <!-- level-3 Resources -->
    <b-card
      no-body
      class="mb-1"
      v-for="(permissions, resource) in inputVal"
      v-bind:key="resource"
    >
      <resource
        v-model="inputVal[resource]"
        @notifyResources="onNotifyResources"
      ></resource>
    </b-card>
    <!-- level-3 Resources -->
  </div>
</template>

<script>
import resource from "./Resource.vue";
import UserPermissionsMixin from "@/mixins/UserPermissionsMixin";

export default {
  mixins: [UserPermissionsMixin],
  name: "Resources",
  data() {
    return {
      repository: "user",
    };
  },
  props: {
    msg: String,
    value: null,
  },
  components: {
    resource,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
