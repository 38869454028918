import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  data() {
    return {
      permissioninfoText: {
        index: "Disabilita il modulo della risorsa",
        store: "Disabilita il tasto crea",
        update: "Disabilita il tasto modifica dettaglio",
        destroy: "Disabilita il tasto elimina",
      },
      resourceDictionary: {
        tasks: "Note, Attività, Appuntamenti",
        registries: "Anagrafiche",
        attributes: "Campi Aggiuntivi",
        agency_contacts: "Contatti",
        agency_setups: "Impostazioni di agenzia",
        claims: "Sinistri",
        claim_parties: "Associati Sinistri",
        correspondences: "Indirizzi",
        councils: "Comuni",
        countries: "Nazioni",
        book_tags: "Codice Documento",
        provinces: "Province",
        documents: "Documenti",
        generated_reports: "Report Generati",
        reports: "Report",
        insurance_risks: "Rischi",
        insurers: "Compagnie",
        registry_groups: "Gruppi",
        risk_branches: "Rami di Rischio",
        risk_types: "Tipi di Rischio",
        insurance_policies: "Polizze",
        address_books: "Recapiti",
        insurance_ancillaries: "Titoli",
        insurance_payments: "Quietanzamenti",
        relative_types: "Relazioni",
        users: "Utenti",
        importers: "Importatori",
        import_logs: "Log Importatori",
        import_maps: "Mappatura Importatori",
        agency_books: "Contabilità di Agenzia",
        book_entries: "Foglio Cassa",
        breakdowns: "Scorpori",
        broker_statements: "Contabilità Produttori",
        brokers: "Produttori / Collaboratori",
        commissions: "Capitolato",
        consolidated_treasuries: "Consolidamenti",
        entry_details: "Dettagli Foglio Cassa",
        insurer_accountings: "Contabilità Compagnie",
        payment_references: "Causali",
        sectors: "Comparti",
        treasuries: "Forme di Pagamento",
        various_accountings: "Movimenti Vari",
        unsolved_payments: "Insoluti",
        mandate_codes: "Codici Mandato",
        cash_entries: "Movimenti",
        cash_references: "Causali",
        import_details: "Dettaglio importatori",
        insurer_participants: 'Compagnie coassicuratrici'
      },
      permissionDictionary: {
        create: "Mostra Dati Creazione",
        edit: "Mostra Dati Modifica",
        store: "Crea",
        update: "Modifica",
        destroy: "Cancella",
        index: "Mostra tutti i record",
        show: "Mostra Dettaglio",
      },
    };
  },
  methods: {
    /* Data utils */
    groupByModule(data) {
      let parsed_data = { modules: [] };
      let tmp_resource = "";
      for (const mod in data) {
        let module_obj = {
          name: "",
          alias: "",
          user_id: this.user.id,
          resources: [],
        };
        module_obj.name = mod;
        module_obj.alias = mod.replaceAll(" ", "_");
        for (const permission in data[mod]) {
          const resource = data[mod][permission].name.split(".")[1];
          if (this.skipResource(resource)) {
            if (resource !== tmp_resource) {
              tmp_resource = resource;
              //Add Permissions
              let permissions = data[mod]
                .filter(
                  (perm) =>
                    perm.name.split(".")[1] === resource &&
                    this.skipPermission(perm.name.split(".")[2])
                )
                .map((permission) => {
                  return {
                    id: permission.id,
                    name: permission.name,
                    value: permission.value,
                    user_id: this.user.id,
                    check: this.setPermissionCheckbox(permission),
                  };
                });
              //Add Resource
              module_obj.resources.push({
                name: resource,
                user_id: this.user.id,
                permissions: permissions,
              });
            }
          }
        }
        this.setInitialCheckboxes(module_obj);
        //Add Module
        parsed_data.modules.push(module_obj);
      }
      return parsed_data;
    },
    setInitialCheckboxes(module) {
      // Resources
      module.resources.forEach((resource) => {
        this.setResourceCheckbox(resource);
      });
      // Modules
      this.setModuleCheckbox(module);
    },
    setPermissionCheckbox(permission) {
      return permission.value === "Y" ? true : false;
    },
    setResourceCheckbox(resource) {
      let permissionAllChecked;
      let permissionAllUnchecked;
      permissionAllChecked = resource.permissions.every(
        (permission) => permission.check === true
      );
      permissionAllUnchecked = resource.permissions.every(
        (permission) => permission.check === false
      );
      // Set Resources custom attributes
      if (permissionAllChecked) {
        resource["check"] = true;
        resource["indeterminate_state"] = false;
      } else if (permissionAllUnchecked) {
        resource["check"] = false;
        resource["indeterminate_state"] = false;
      } else {
        resource["check"] = false;
        resource["indeterminate_state"] = true;
      }
    },
    setModuleCheckbox(module) {
      let resourceAllChecked;
      let resourceAllUnchecked;
      let resourceIndeterminateState;

      resourceAllChecked = module.resources.every(
        (resource) => resource.check === true
      );
      resourceAllUnchecked = module.resources.every(
        (resource) => resource.check === false
      );
      resourceIndeterminateState = module.resources.some(
        (resource) => resource.indeterminate_state === true
      );

      // Set Modules custom attributes
      if (resourceAllChecked) {
        module["check"] = true;
        module["indeterminate_state"] = false;
      } else if (resourceAllUnchecked && !resourceIndeterminateState) {
        module["check"] = false;
        module["indeterminate_state"] = false;
      } else {
        module["check"] = false;
        module["indeterminate_state"] = true;
      }
    },
    skipPermission(action) {
      const excludedActions = ["edit", "create"];
      return !excludedActions.includes(action);
    },
    skipResource(resource) {
      const excludedResources = [
        "provinces",
        "countries",
        "risk_types",
        "risk_branches",
        "users",
        "job_spools",
        "tags",
        "communication_procedures",
        "communication_details",
        "communication_groupings",
        "categories",
        "import_groups",
        "import_files",
        "temp_imports",
      ];
      return !excludedResources.includes(resource);
    },

    /* Dictionaries */
    resourcesDictionary(resource) {
      return this.resourceDictionary[resource]
        ? this.resourceDictionary[resource]
        : "-----";
    },
    permissionsDictionary(resource) {
      return this.permissionDictionary[resource.split(".")[2]]
        ? this.permissionDictionary[resource.split(".")[2]]
        : "-----";
    },

    /* API calls*/
    deletePermission(permission) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.destroy_permission(permission.user_id, permission.id);
    },
    addPermission(permission) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.store_permission(permission.user_id, {
        permission_id: permission.id,
      });
    },

    /* getRelatedPermissionsIds */
    getRelatedPermissionsIds(changed_permission) {
      const permissionAction = changed_permission.name.split(".")[2];
      const permissionsMap = {
        index: [
          "store",
          "update",
          "create",
          "edit",
          "destroy",
          "show",
          "index",
        ],
        store: ["create", "store"],
        update: ["edit", "update"],
        destroy: ["destroy"],
      };

      return this.inputVal.permissions
        .filter((permission) =>
          permissionsMap[permissionAction].includes(
            permission.name.split(".")[2]
          )
        )
        .map((permission) => permission.id);
    },

    /* Permissions */
    showPermissionInfo(permission_action) {
      return this.permissioninfoText[permission_action]
        ? this.permissioninfoText[permission_action]
        : "Nessuna informazione disponibile";
    },
    hidePermissionOverlay(changed_permission) {
      this.$refs.permission.find(
        (permission) => permission.inputVal.id === changed_permission.id
      ).permission_overlay_status = false;
    },
    onChangePermission(val) {
      this.permission_overlay_status = true;
      this.inputVal.value = val ? "Y" : "N";
      this.$emit("notifyPermissions", this.inputVal);
    },
    onNotifyPermissions(changed_permission) {
      let promises = [];
      if (changed_permission.check) {
        promises = this.addPermission(changed_permission);
      } else {
        promises = this.deletePermission(changed_permission);
      }
      Promise.all([promises])
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Modifica Permesso Completata`,
          });
          this.$emit("notifyResource");
          this.hidePermissionOverlay(changed_permission);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.hidePermissionOverlay(changed_permission);
        });
    },

    /*Resources */
    onChangeResource(val) {
      this.resource_overlay_status = true;
      let promises = [];
      this.inputVal.permissions
        .filter((permission) => permission.check === !val)
        .forEach((permission) => {
          permission.check = val;
          permission.value = val ? "Y" : "N";
          if (permission.check) {
            promises.push(this.addPermission(permission));
          } else {
            promises.push(this.deletePermission(permission));
          }
        });

      Promise.all(promises)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Modifica Risorsa Completata`,
          });
          this.setResourceCheckbox(this.inputVal);
          this.$emit("notifyResources");
          this.resource_overlay_status = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.resource_overlay_status = false;
        });
    },
    onNotifyResource() {
      this.setResourceCheckbox(this.inputVal);
      this.$emit("notifyResources");
    },
    onNotifyResources() {
      this.$emit("notifyModule");
    },

    /* Modules */
    onChangeModule(val) {
      this.module_overlay_status = true;
      let promises = [];

      this.inputVal.resources.forEach((resource) => {
        resource.check = val;
        resource.indeterminate_state = false;
        resource.permissions
          .filter((permission) => permission.check === !val)
          .forEach((permission) => {
            permission.check = val;
            permission.value = val ? "Y" : "N";
            if (permission.check) {
              promises.push(this.addPermission(permission));
            } else {
              promises.push(this.deletePermission(permission));
            }
          });
      });

      Promise.all(promises)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Modifica Modulo Completata`,
          });
          this.setModuleCheckbox(this.inputVal);
          this.module_overlay_status = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.module_overlay_status = false;
        });
    },
    onNotifyModule() {
      this.setModuleCheckbox(this.inputVal);
    },
  },
};
