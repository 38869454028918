<template>
  <!-- level-6 Permission -->
  <b-overlay center :id="inputVal" :show="permission_overlay_status">
    <b-list-group-item
      variant="warning"
      class="d-flex justify-content-between align-items-center"
    >
      <span class="action">{{ permissionsDictionary(inputVal.name) }}</span>
      <b-form-checkbox v-model="inputVal.check" @change="onChangePermission">
        <span style="cursor: pointer" :id="`tooltip-${inputVal.id}`"
          ><b-icon icon="info-circle"
        /></span>
        <b-tooltip placement="top" :target="`tooltip-${inputVal.id}`">
          {{ showPermissionInfo(inputVal.name.split(".")[2]) }}
        </b-tooltip>
      </b-form-checkbox>
    </b-list-group-item>
  </b-overlay>
  <!-- level-6 Permission -->
</template>

<script>
import UserPermissionsMixin from "@/mixins/UserPermissionsMixin";

export default {
  mixins: [UserPermissionsMixin],
  name: "Permission",
  props: {
    msg: String,
    value: null,
  },
  data() {
    return {
      repository: "user",
      permission_overlay_status: false,
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.action {
  font-weight: bold;
}
</style>
