<template>
  <!-- level-4 Resource -->
  <b-overlay center :id="inputVal.name" :show="resource_overlay_status">
    <b-card-header
      header-tag="header"
      class="d-flex justify-content-between align-items-center"
      role="tab"
    >
      <label
        v-b-toggle="`accordion_resource_${inputVal.name}`"
        variant="info"
        >{{ resourcesDictionary(inputVal.name) }}</label
      >
      <b-form-checkbox
        v-model="inputVal.check"
        :indeterminate="inputVal.indeterminate_state"
        @change="onChangeResource"
      >
      </b-form-checkbox>
    </b-card-header>
    <b-collapse :id="`accordion_resource_${inputVal.name}`">
      <permissions
        v-model="inputVal"
        @notifyResource="onNotifyResource"
      ></permissions>
    </b-collapse>
  </b-overlay>
  <!-- level-4 Resource -->
</template>

<script>
import permissions from "./Permissions.vue";
import UserPermissionsMixin from "@/mixins/UserPermissionsMixin";

export default {
  mixins: [UserPermissionsMixin],
  name: "Resource",
  props: {
    msg: String,
    value: null,
  },
  data() {
    return {
      repository: "user",
      resource_overlay_status: false,
    };
  },
  components: {
    permissions,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped lang="scss">
:deep(header) {
  label {
    margin-bottom: 0px !important;
  }
}
.card .card-header {
  background-color: #e9ecef;
  border-color: transparent;
}
</style>
