<template>
  <!-- level-5 Permissions -->
  <b-card-body>
    <b-list-group
      v-for="(item, index) in inputVal.permissions.length"
      v-bind:key="index"
    >
      <permission
        v-model="inputVal.permissions[index]"
        @notifyPermissions="onNotifyPermissions"
        ref="permission"
      ></permission>
    </b-list-group>
  </b-card-body>
  <!-- level-5 Permissions -->
</template>

<script>
import permission from "./Permission.vue";
import UserPermissionsMixin from "@/mixins/UserPermissionsMixin";

export default {
  name: "Permissions",
  mixins: [UserPermissionsMixin],
  props: {
    msg: String,
    value: null,
  },
  data() {
    return {
      repository: "user",
    };
  },
  components: {
    permission,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.card .card-header {
  background-color: #f9991e;
  border-color: transparent;
}
</style>
