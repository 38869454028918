<template>
  <!-- level-2 Module -->
  <b-overlay center :id="inputVal.alias" :show="module_overlay_status">
    <b-card-header
      header-tag="header"
      class="d-flex justify-content-between align-items-center"
      role="tab"
    >
      <label v-b-toggle="`accordion_module_${inputVal.alias}`" variant="info">{{
        inputVal.name
      }}</label>
      <b-form-checkbox
        v-model="inputVal.check"
        :indeterminate="inputVal.indeterminate_state"
        @change="onChangeModule"
      >
      </b-form-checkbox>
    </b-card-header>
    <b-collapse :id="`accordion_module_${inputVal.alias}`">
      <b-card-body>
        <resources
          v-model="inputVal.resources"
          @notifyModule="onNotifyModule"
        ></resources>
      </b-card-body>
    </b-collapse>
  </b-overlay>
  <!-- level-2 Module -->
</template>

<script>
import resources from "./Resources.vue";
import UserPermissionsMixin from "@/mixins/UserPermissionsMixin";

export default {
  mixins: [UserPermissionsMixin],
  name: "Module",
  props: {
    value: null,
    msg: String,
  },
  data() {
    return {
      repository: "user",
      module_overlay_status: false,
    };
  },
  components: {
    resources,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(header) {
  label {
    margin-bottom: 0px !important;
  }
}
</style>
